import React from 'react';

const IconLogo = () => (
  <svg width="95" height="96" viewBox="0 0 95 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 24.0005L47.499 0L95 24.0005V71.9995L47.499 96L0 71.9995V24.0005Z" fill="#64FFDA"/>
    <path d="M0 24.0005L47.499 0L95 24.0005V71.9995L47.499 96L0 71.9995V24.0005Z" fill="#64FFDA"/>
    <path d="M5 27.2504L47.4991 6L90 27.2504V69.7496L47.4991 91L5 69.7496V27.2504Z" fill="#020C1B"/>
    <path d="M34.1605 66V29.6364H41.8487V45.6697H42.3281L55.4141 29.6364H64.6293L51.1349 45.9183L64.7891 66H55.5916L45.6307 51.0497L41.8487 55.6662V66H34.1605Z" fill="#64FFDA"/>
    </svg>

);

export default IconLogo;
