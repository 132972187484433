import React from 'react';

const IconHex = () => (
  <svg width="95" height="96" viewBox="0 0 95 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Hexagon</title>
    <path d="M0 24.0005L47.499 0L95 24.0005V71.9995L47.499 96L0 71.9995V24.0005Z" fill="#64FFDA"/>
  </svg>
  
);

export default IconHex;
